/**
 * Node.js module for all known Forge message digests.
 *
 * @author Dave Longley
 *
 * Copyright 2011-2017 Digital Bazaar, Inc.
 */
module.exports = require('./md');

require('./md5');
require('./sha1');
require('./sha256');
require('./sha512');
